<template>
  <div id="itemlist">
            <a-table
                :columns="columns"
                :row-key="record => record.id+record.fid"
                :data-source="rsData"
                :pagination="pagination"
                :loading="loading"
                bordered
            >                                     
                <template slot="titles" slot-scope="record"> {{ record.acts_signdetail[0].actitle }}</template>
                <!--<template slot="join" slot-scope="record"> {{ GetJoin(record.acts_signdetail) }}</template>-->
                <template slot="score" slot-scope="record"> {{ GetScore(record.acts_signdetail) }}</template>   
                <!--<template slot="sdate" slot-scope="record"> {{ formatDate(record.sdate) }}</template>-->
                <a slot="action" slot-scope="obj">
                    <b-button-group size="sm">
                        <b-button @click="openList(obj)" variant="link"> 
                            <b-icon icon="list-ul" variant="muted"></b-icon>
                        </b-button>                       
                    </b-button-group>                                               
                </a>
                <template slot="title" slot-scope="record">
                    {{yDate}} 總學分 : {{ totalAll + ' 實際得分 : '+ GetScores(record)}} 
                </template>                            
            </a-table> 
            <b-modal ref="myActList" id="modal-2" size="lg" title="明細"   centered hide-footer>   
                    <div v-if=" mylists!==null"> 
                        <span v-text="'活動名稱 : '+mylists.acts_signdetail[0].actitle" style="font-weight:bold" /><br/>
                        <span v-text="'該活動總得分 : '+GetScoreByACT(mylists.acts_signdetail) +' 實際得分數 : '+ GetScoreReal(mylists.acts_signdetail) +' | 該活動出席次數 : '+ GetJoin(mylists.acts_signdetail)" style="font-weight:bold" />                
                        <div class="list-group-item" :id="'word' + element.id" style="padding:3px;margin:2px;max-height:160px;" v-for="element in mylists.acts_signdetail" :key="element.id">                 
                              <div class="container">
                                <div class="row" :style="{ backgroundColor:element.states=='Y'?'#f9d089':'',fontWeight:element.states=='Y'?'bold':''}" >                                  
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;" v-text="'課程時間 :' + element.sdate +'~' + element.edate " />                          
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;" v-text="'姓名 : '+element.username+' | 角色 : ' + getRole(element.roles) +' | 分數:'+element.score" />
                                  <div class="text-left" style="padding:1px;margin:1px;font-size: 14px;">
                                  <span v-text="'報到 : '+(element.states=='Y'?'✅已報到 | 報到時間 :'+formatDateTime(element.arrival):'⭕未報到')" />
                                  <!--<b-button v-if="element.states!=='Y' && element.sdate >getdate(currentTime)" variant="link" @click="delItem(element,element.roles)" size="sm">
                                    <b-icon icon="trash" aria-hidden="true"></b-icon>取消該課程
                                  </b-button>-->
                                  </div>                                                                    
                                </div>
                              </div>                                                                
                        </div>   
                    </div>  
            </b-modal>             
  </div>
</template> 

<script>
import * as moment from "moment/moment";
import { joinService } from "../../_services";

const queryData = params => {
  return joinService.myscores(params) 
};

const columns = [
  {
    title: '活動名稱',
    dataIndex: '',
    width: '55%',
    ellipsis: true,  
    scopedSlots: { customRender: 'titles' },    
  }, 
  /*{
    title: '出席次數',
    dataIndex: '',

    ellipsis: true,
    scopedSlots: { customRender: 'join' },    
  },  */    
  {
    title: '學分',
    dataIndex: '',
    ellipsis: true,
    scopedSlots: { customRender: 'score' },    

  },  
   /*{
    title: '活動開始日期',
    dataIndex: '',
    ellipsis: true,
    scopedSlots: { customRender: 'sdate' },    

  },*/ 
  { 
    title: ' 清單 ', 
    dataIndex: '', 
    key: 'id', 
    ellipsis: true,
    scopedSlots: { customRender: 'action' } ,

    },  

];

export default {
  name: "Actscore",
  props: {
    useid : Number, 
    yDate : String,
  },
  data() {
    return {
      columns,
      loading:false,
      myJson: [],
      rsData: [],
      mylists:null,
      spinning: false, 
      total:0,
      totalAll:0,
      currentTime:moment(),
      pagination: {defaultPageSize:10},
    };
  },
  methods: {

    sortRS(objs){
      return objs.sort((a,b) => (a.sdate > b.sdate))
    },

    getdate(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },

    catImg(imgs) {
      return require(`../../assets/img/${imgs}`);
    },

    getxt(val) {
      return val.substring(0, 32);
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    getRole(val){
      let tmp=""
      val=="actor"? tmp="參加者":"";
      val=="broker"? tmp="主辦單位":"";
      val=="coach"? tmp="講師":"";
      val=="volunteer"? tmp="志工":"";
      val=="D"? tmp="下架":"";
      return tmp
    }, 
    
    formatDate(val){
        return moment(val).format("YYYY-MM-DD"); 
    }, 
    formatDateTime(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },       
    shorts(rs){
      return rs.sort((a,b)=>(a.sdate-b.sdate))
    },

    fetch(
      params = {
          id: this.useid,
          date: this.yDate,               
      }) {
      
      this.loading=true
      queryData({
        ...params,
      }).then(({ data }) => {
        this.rsData = data
            if(this.rsData===null){
                this.loading = false
                this.total=0
            }else{
                if(this.rsData.length>0){
                this.GetTotal()
                this.loading = false
            }
        }


      });
    }, 
    
    GetJoin(obj){
      console.log(obj);
        let tmpArr = obj.filter(
          rs => rs.sid>0
        );
        return tmpArr.length > 0 ? tmpArr.length:0
    },
    GetScore(obj){
        let tmpArr = obj.filter(
          rs => rs.sid>0
        );
        let score =0;
        if(tmpArr.length > 0){
            for(var i in tmpArr){
                score+=tmpArr[i].score
            }
        }
        return score
    },

    GetScoreByACT(obj){
        let tmpArr = obj.filter(
          rs => rs.sid>=0
        );
        let score =0;
        if(tmpArr.length > 0){
            for(var i in tmpArr){
                score+=tmpArr[i].score
            }
        }
        return score
    },    
    
    GetScoreReal(obj){
        let tmpArr = obj.filter(
          rs => rs.sid>0
        );
        let score =0;
        if(tmpArr.length > 0){
            for(var i in tmpArr){
                score+=tmpArr[i].score
            }
        }
        return score
    },    

    GetScores(obj){
        if(obj.length>0){
            return this.total
        }else{
            return 0
        }
    },

    async GetTotal(){
        this.total=0
        if(this.rsData.length > 0){
            for(var i in this.rsData){
                for(var j in this.rsData[i].acts_signdetail){
                    this.totalAll +=this.rsData[i].acts_signdetail[j].score
                    if(this.rsData[i].acts_signdetail[j].sid>0){
                        this.total+=this.rsData[i].acts_signdetail[j].score
                    }
                }
            }
        }       
    },

    openList(obj){
        this.$refs['myActList'].show()
        this.mylists =obj
    } 
    
  },
  created() {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
}
.card-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
  font-size: 13px;
  word-break: "break-word";
}
.card-text {
  font-size: 13px;
  margin: 5px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-body {
  padding: 10px;
}
.card-img-top {
}
#cheader {
  margin: 0px auto;
  padding: 0px;
  background-color: #ffffff;
  width: 50%;
  border-top-left-radius: 10.5rem;
  border-top-right-radius: 10.5rem;
}
#cbodyer {
  padding: 10px;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

</style>





