<template>
  <div class="about" >
    <div class="container">
        <vuejs-datepicker 
        :value="this.defaultDate"
        :format="DatePickerFormat"
        :language="language"
        :bootstrap-styling="true"
        v-model="yDate"
        @selected="doYDate"
        minimum-view="year"            
        ></vuejs-datepicker>
    </div> 
      <ul>
        <li>
          <div class="mt-3" >
            <Actscore :useid="useid" :yDate="setDates(yDate)" ref="con2"/>
          </div>
        </li>
      </ul> 
  </div>
</template>
<script>
import {mapState} from 'vuex';
import * as moment from "moment/moment";
import Actscore from './panel/Actscore.vue'
import vuejsDatepicker from 'vuejs-datepicker';
import 'moment/locale/zh-tw';

export default {
  name: 'Mymailscore',
  components: {
    Actscore,
    vuejsDatepicker,
  }, 
  props: {
    msg: String
  },
  data() {
    const now = new Date()
    const today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format("YYYY-MM-DD") 
    return {  
      useid:0,
      defaultDate: today ,
      DatePickerFormat: 'yyyy',
      yDate: moment(today).format("YYYY"),
      language:{
        language: 'Chinese', 
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'], 
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'], 
        days: ['日', '月', '火', '水', '木', '金', '土'], 
        rtl: false, 
        ymd: false, 
        yearSuffix: '年'
      }        
    };
  }, 
  computed: {
    ...mapState("account", ["status", "user","linecheck"]),
  },   
  mounted(){
        if(this.user!==null){  
          this.useid = this.user.data.user.id 
          const now = new Date()
          const today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format("YYYY-MM-DD") 
          this.yDate = moment(today).format("YYYY") 
          this.showDATA()
        }
  },
  methods: {
    doYDate(date) {
       this.yDate=moment(date).format("YYYY");
       this.showDATA()
    },    
    changedYear(e){
        console.log(e)
    },            

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    showDATA(){
      setTimeout(() => {
        this.$refs["con2"].fetch();  
      }, 500);                 

    },
    setDates(val){
        return moment(val).format("YYYY");
    },   
  },      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}

</style>

